@media (max-width: 992px) {
  .account-layout {
    flex-direction: column;
    flex-wrap: wrap;
  }

  .account-layout__nav-column {
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  .account-layout__content-column {
    flex: 0 0 100%;
  }
}

@media (min-width: 992px) {
  .account-layout__nav-column {
    -ms-flex: 0 0 280px;
    flex: 0 0 280px;
  }

  .account-layout__content-column {
    flex: 1;
  }
}

@media (max-width: 992px) {
  .account-layout__nav-column-content {
    position: relative !important;
    z-index: 1 !important;
    top: 0 !important;
  }
}

@media only screen and (max-width: 1024px) {
  .account-layout {
    width: 100% !important;
  }
}

.account-layout {
  max-width: 1300px;
}

.account-layout__nav-column {
  /*background-color: var(--gray100);*/
  position: relative;
}

.account-layout__nav-column-content {
  position: sticky;
  z-index: 999;
  top: 70px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
}

.account-layout__content-column {
  background-color: var(--gray100);
  padding: 24px 40px 60px 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
}

.content-column__listing {
  background-color: var(--gray100);
  margin-left: 0;
}

.nav-column__title-box {
  margin: 14px;
  height: 50px !important;
  width: 100%;
  border: 0;
  border-radius: 0;
  font-size: 0.9rem;
}

.account-layout__nav-item {
  display: flex;
  padding-left: 40px !important;
  padding-right: 16px !important;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  color: var(--primary-color) !important;
  position: relative;
  font-weight: 600;
  font-size: 0.9rem;
}

.account-layout__nav-item::before {
  content: ' ';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background-color: var(--primary-color);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.account-layout__nav-item.disabled {
  opacity: 0.5;
}

.account-layout__nav-item:not(.disabled):hover {
  cursor: pointer;
  font-weight: bold;
  background-color: var(--gray100) !important;
}

.account-layout__nav-item:not(.disabled):hover::before {
  opacity: 1;
}

.account-layout__nav-item:not(.scroller):not(.disabled).active {
  font-weight: bold;
  background-color: var(--gray100) !important;
}

.account-layout__nav-item:not(.scroller):not(.disabled).active::before {
  opacity: 1;
}

.account-layout__content-wrapper {
  padding: 20px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.account-layout__header {
  padding-bottom: 24px;
}

.account-layout__footer {
  margin-top: 24px;
}
